.MainSec {
    height: 100vh;
    width: 100vw;
    /* background-image: linear-gradient(orange 2%,white); */
    background: #ffbc45;
    animation: change 3s ease forwards;
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: 100%;
    grid-template-areas: 'MainSeclogo Google_Login';
}

#MainSeclogo {
    grid-area: MainSeclogo;
}

.Main_Sec_Title {
    width: 16vh;
    height: 16vh;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    animation: move 4s ease 0.5s backwards;
}

#Google_Login {
    grid-area: Google_Login;
    position: relative;
}
#Google_Login > div {
    position: absolute;
    top: 50%;
    animation: rocket 6.5s ease;
}

.SubMain {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Main_Sec_Rock {
    width: 34vh;
    animation: rocket 5.5s ease;
}

.bottomTextDiv {
    position: fixed;
    /* right: 43%; */
    width: 100%;
    bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.copyRightText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0;
}

@keyframes change {
    0% {
        background-color: #ffbc45;
    }
    40% {
        background-color: #ffbc45;
    }

    100% {
        background-color: #fafafa;
    }
}

@keyframes move {
    from {
        transform: translateX(500px) translateY(-50%);
    }
    to {
        transform: translateX(0) translateY(-50%);
    }
}

@keyframes rocket {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes double {
    0% {
        height: 350px;
        width: 950px;
    }
    30% {
        height: 350px;
        width: 950px;
    }
    100% {
        height: 300px;
        width: 900px;
    }
}

/* @media screen and (max-width: 1910px) and (min-width: 1081px) {
    .MainSeclogo {
       
        height: 300px;
        width: 800px;
        top: 50%;
      
    }
    .Main_Sec_Title {
        height: 140px;
        width: 140px;
       
        top: 50%;
        left: 15%;
        transform: translateY(-50%);
       
    }
    .Main_Sec_Rock {
        right: -15%;
    }
    @keyframes move {
        from {
            transform: translateX(350px) translateY(-70px);
        }
        to {
            transform: translateX(0) translateY(-70px);
        }
    }

    @keyframes double {
        0% {
            height: 250px;
            width: 710px;
        }
        30% {
            height: 300px;
            width: 720px;
        }
        100% {
            height: 250px;
            width: 710px;
        }
    }
}

@media screen and (max-width: 1080px) and (min-width: 769px) {
    .MainSeclogo {
        
        height: 250px;
        width: 594px;
        top: 50%;
        
    }
    .Main_Sec_Title {
        height: 140px;
        width: 140px;
       
        top: 50%;
        left: 15%;
        transform: translateY(-50%);
       
    }
    .Main_Sec_Rock {
        right: -18%;
    }
    @keyframes move {
        from {
            transform: translateX(350px) translateY(-70px);
        }
        to {
            transform: translateX(0) translateY(-70px);
        }
    }

    @keyframes double {
        0% {
            height: 250px;
            width: 710px;
        }
        30% {
            height: 300px;
            width: 720px;
        }
        100% {
            height: 250px;
            width: 710px;
        }
    }
}

@media (max-width: 768px) {
    .MainSeclogo {
        position: relative;
        height: 228px;
        width: 581px;
        top: 50%;
        transform: translateY(-50%);
       
    }
    .Main_Sec_Title {
        height: 130px;
        width: 130px;
        position: absolute;
        top: 49%;
        left: 12%;
        transform: translateY(-50%);
       
    }
    .Main_Sec_Rock {
        height: 350px;
        width: 250px;
        position: absolute;
        right: -18%;
        top: 60%;
        transform: translateY(-50%);
      
    }
    @keyframes move {
        from {
            transform: translateX(250px) translateY(-65px);
        }
        to {
            transform: translateX(0) translateY(-65px);
        }
    }

    @keyframes double {
        0% {
            height: 228px;
            width: 581px;
        }
        30% {
            height: 278px;
            width: 591px;
        }
        100% {
            height: 228px;
            width: 581px;
        }
    }
} */

/* ----------------- new splash screen ui css -------------------------  */
.outerDiv {
    background-color: blue;
    /* overflow: hidden; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
}
.manpage {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.newMainSec {
    position: absolute;
    width: 100vmax;
    height: 100vmax;

    background: #ffbc45;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: newChange 2s ease-out 1.5s forwards;
}
.newMainSecText {
    color: white;
    font-size: 8vw;
    animation: newMainSecTextChange 2s ease-out 1.5s forwards;
}

.iconDiv {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    background: #ffbc45;
}
.iconImage {
    opacity: 0;
    z-index: 1;
    animation: iconImageChange 2s ease-out 1.5s forwards;
}
.iconText {
    margin-top: 10px;
    color: white;
    font-size: 3rem;
}
#NewGoogle_Login > div {
    animation: rocket 5.5s ease;
}
@keyframes newChange {
    0% {
        border-radius: 50%;
        transform: scale(1);
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        border-radius: 50%;
        transform: scale(0.1);
        opacity: 0;
        display: none;
    }
}
@keyframes newMainSecTextChange {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes iconImageChange {
    0% {
        opacity: 0;
        width: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        width: 53%;
    }
}
