.colorInputDiv {
    display: flex;
    align-items: start;
    gap: 10%;
}

.sliderr {
    appearance: none;
    width: 210px;
    height: 10px;
    vertical-align: bottom;
    background: transparent;
}

.sliderr::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 40px; /* Set a specific slider handle width */
    height: 40px; /* Slider handle height */
    background: url('../../../Assets/appModeAi/sliderThumb.svg');
    background-size: cover; /* Cover the entire thumb */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%; /* Make it circular */
    /* margin-left: 10px; */
    margin-bottom: -10px;
    /* z-index: 10000000; */
}

.sliderr__track {
    /* background: linear-gradient(
        to right,
        rgb(247, 233, 224),
        rgb(243, 146, 87)
    ); */
    background-color: rgb(243, 146, 87);
    width: 190px;
    z-index: -1;
    border-radius: 10px;
    height: 10px;
    position: absolute;
    /* left: 20px; */

    top: 15px;
    left: 14px;
}

.colorSliderMinMax {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
/* .colorSliderMinMax p {
    border: 1px solid #717171;
} */

/* ------------------------- Multi Range Slider CSS ------------------------------ */
.AiSlider_container {
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.AiSlider_slider {
    position: relative;
    width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 5px;
    height: 8px;
}

.slider__track {
    /* background: linear-gradient(to right, rgb(243, 188, 188), rgb(246, 60, 60)); */
    width: 100%;
    z-index: 1;
}

.slider__range {
    /* background-color: #1ece07; */
    z-index: 2;
}

.slider__left-value,
.slider__right-value {
    color: #030303;
    font-size: 12px;
    margin-top: 20px;
}

.slider__left-value {
    left: 6px;
}

.slider__right-value {
    right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    /* background: url('../../../Assets/appModeAi/sliderThumb.svg');  */
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 210px;
    outline: none;
}

.thumb--left {
    z-index: 3;
}

.thumb--right {
    z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    /* background-color: #09a2ef; */
    border: none;
    /* border-radius: 50%; */
    /* box-shadow: 0 0 1px 1px #ced4da; */
    /* cursor: pointer; */
    /* height: 18px;
    width: 18px; */
    /* margin-top: 4px; */
    pointer-events: all;
    position: relative;

    /* my Style */
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 40px;
    height: 40px;
    background: url('../../../Assets/appModeAi/sliderThumb.svg');
    background-size: cover; /* Cover the entire thumb */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%; /* Make it circular */
    margin-top: 16px;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    border: none;
    pointer-events: all;
    position: relative;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 40px;
    height: 40px;
    background: url('../../../Assets/appModeAi/sliderThumb.svg');
    background-size: cover; /* Cover the entire thumb */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%; /* Make it circular */
    margin-top: 10px;
}

/* ----------------------------------------------------------- */
.AiSlider_colorTrainInputNum {
    width: 52px;
    height: 24px;
    border-radius: 15px;
    border: 1px solid #717171;
    text-align: center;
    font-family: Halcyon_SemiBold;
}
